import React from "react"
import Layout from "../components/layout"
import "../styles/parts/404.sass"
import lost from "../icons/pages/404.svg"
const Error = () => {
    return (
        <Layout>
            <div className="container" id = "error">
                <img src={lost} alt="页面走丢了" />
            </div>
        </Layout>
    )
}

export default Error